<template>
  <div>
    <div v-if="Object.keys(candidateBranchModels).length == 0" class="mb-2">
      <SharedSelect
        class="mt-2"
        :value="null"
        :placeholder="isFetchingData ? placeholder : '查無資料'"
      />
    </div>
    <template>
      <!-- branch select -->
      <div v-for="(level, index) in Object.keys(candidateBranchModels)" :key="level" class="mb-2">
        <SharedSelect
          class="mt-2"
          v-model="selectedBranchIds[index]"
          :options="formatBranchModelsToOptions(candidateBranchModels[level])"
          :placeholder="placeholder"
          @input="handleAnyBranchSelected(selectedBranchIds[index], level)"
        />
      </div>
    </template>
  </div>
</template>

<script>
// NOTE: branch -> 分區或分店, area -> 分區, shop -> 分店
import branchApi from "@/apis/liff/v2/branch"
import SharedSelect from "@/components/Page/Liff/Shared/Select"
import branchMixin from "@/mixins/Dashboard/branches"
import _ from "lodash"

export default {
  props: {
    value: { // NOTE: branch id of a shop
      type: String,
      required: false,
    },
    branchProvider: { // example: "waltily.branch", "waltily.branch:N004", "waltily.branch:N004:true"
      type: String,
      required: true,
    },
    showBranchCode: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "請選擇區域/分店",
    },
  },
  mixins: [branchMixin],
  components: {
    SharedSelect,
  },
  data() {
    return {
      selectedBranchIds: [null],
      selectedShopId: null,
      candidateBranchModels: {}, // NOTE: key -> branch level(does not start with 0), value -> array of branch models
      isFetchingData: false,
    }
  },
  computed: {
    deepestCandidateLevel() {
      return Math.max(...Object.keys(this.candidateBranchModels))
    },
  },
  mounted() {
    this.selectedShopId = this.value
  },
  methods: {
    async handleAnyBranchSelected(branchId, currentSelectionLevel) {
      const selectedBranch = this.candidateBranchModels[currentSelectionLevel].find(branch => branch.id == branchId)

      if (currentSelectionLevel !== this.deepestCandidateLevel) {
        this.cleanDeeperLevelOptions(currentSelectionLevel)
      }

      if (branchId === null) {
        this.$emit('input', null)
      } else if (this.isShop(selectedBranch)) {
        this.$emit('input', branchId)
        this.$emit('setBranchModel', selectedBranch)
        console.log('selected shop', branchId)
        this.$emit('selectedShop', branchId)
      } else if (this.isArea(selectedBranch)) {
        this.$emit('input', null)
        console.log('selected area', branchId)
        this.$emit('selectedArea', branchId)
        await this.fetchBranchesAndPrepareCandidates({ type: 'level', branch_id: branchId })
        if (this.candidateBranchModels[currentSelectionLevel].length > 0) {
          this.selectedBranchIds.push(null)
        }
      }
    },
    async fetchBranchesAndPrepareCandidates(params) {
      try {
        this.isFetchingData = true
        this.$emit('loading')
        const response = await branchApi.getBranches(params)
        const childrenBranchModels = response.data.data || []
        if (childrenBranchModels.length > 0) {
          const level = childrenBranchModels[0].level
          this.$set(this.candidateBranchModels, level, childrenBranchModels)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.isFetchingData = false
        this.$emit('loaded')
      }
    },
    cleanDeeperLevelOptions(maximumPreservableLevel) {
      this.candidateBranchModels = Object.fromEntries(
        Object.entries(this.candidateBranchModels)
          .filter(([branchLevel, branchModel]) => branchLevel <= maximumPreservableLevel) // eslint-disable-line
      )
      this.selectedBranchIds = this.selectedBranchIds.slice(0, Object.keys(this.candidateBranchModels).length)
    },
    isArea(branchModel) {
      return _.get(branchModel, 'branch_type') === 'area'
    },
    isShop(branchModel) {
      return [null, 'shop'].includes(_.get(branchModel, 'branch_type'))
    },
    formatBranchModelsToOptions(branchModels) {
      if (!branchModels) {
        return []
      }
      return this.sortBranch(branchModels, branchModels[0]['org_id'], this.showBranchCode)
    },
    // below are APIs for parent components to call
    async fetchBranchesByProvider() {
      if (!this.branchProvider) {
        throw new Error('branch provider is not set')
      }
      let providerSetting = this.branchProvider.split(':')
      switch (true) {
        case providerSetting[1] === 'all':
          // 全部分店
          return await this.fetchBranchesAndPrepareCandidates({ type: 'all' })
        case providerSetting[1] === 'level':
        case providerSetting[1] === undefined:
          // 階級獲取 只是從parent_id - null
          return await this.fetchBranchesAndPrepareCandidates({ type: 'level', branch_id: null })
        default:
          // 階級獲取 從特定分店開始
          return await this.fetchBranchesAndPrepareCandidates({ type: 'specific', branch_code: providerSetting[1] })
      }
    },
  },
}
</script>
